
import { Component, Watch, Vue } from "vue-property-decorator";
import homedata from "@/config/home.config";
import Header from "@/components/Header.vue"; // @ is an alias to /src
import Fooder from "@/components/Fooder.vue";
import Title from "@/components/Title.vue";
import Tab from "@/components/Tabs/Tab.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import ImageTabs from "./ImageTabs/ImageTabs.vue";
import Advantage from "./Advantage/Advantage.vue";
import ImageTab from "./ImageTabs/Tab.vue";
import HomeTabContent from "./ImageTabs/HomeTabContent.vue";
import MobileCard from "./MobileCard.vue";
import { getNewsList } from "@/api/index";

type dynamicsData = {
  id: string;
  date: string;
  title: string;
  brief: string;
  filePath: string;
  jpgpath: string;
};
@Component({
  components: {
    Header,
    Title,
    Tabs,
    Tab,
    ImageTabs,
    ImageTab,
    HomeTabContent,
    Advantage,
    Fooder,
    MobileCard,
  },
})
export default class HomeView extends Vue {
  homedata: any = homedata;
  dynamicsData: dynamicsData[] = [];
  tabShowName = "name1";

  playerTopOptions = {
    // playbackRates: [1.0], //播放速度
    // autoplay: true, // 如果true,浏览器准备好时开始回放。
    // muted: true, // 默认情况下将会消除任何音频。
    // loop: true, // 导致视频一结束就重新开始。
    // normalizeAutoplay: false,
    // preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
    // language: "zh-CN",
    aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
    autoplay: true, //如果true,浏览器准备好时开始回放。
    controls: false, //控制条
    preload: "auto", //视频预加载
    muted: "muted", //默认情况下将会消除任何音频。
    loop: true, //导致视频一结束就重新开始。
    language: "zh-CN",
    sources: [
      {
        type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
        src: require("@/assets/laya-top.mp4"), // url地址
      },
    ],
    // hls: true,
    notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
    poster: require("@/assets/home_banner.jpg"),
    width: document.documentElement.clientWidth,
  };

  caseActiveKey = "3D";
  caseActiveList = homedata[this.caseActiveKey].list;
  caseActiveGame = 0;

  get windowWidth() {
    return this.$store.state.windowWidth;
  }

  @Watch("$i18n.locale")
  async onChangeLocale() {
    await this.newList();
  }

  async created() {
    this.caseActiveGame = 0;
    await this.newList();
  }
  mounted() {
    this.changeVideo();
  }
  detail(item) {
    this.$router.push({
      name: "enginedynamicsDetail",
      params: {
        id: item.id,
      },
    });
  }
  winOpen(url: string) {
    window.open(url);
  }
  caseTabChange(key) {
    this.caseActiveKey = key;
    this.caseActiveList = homedata[this.caseActiveKey].list;
    this.caseActiveGame = 0;
    this.changeVideo();
  }
  imgTabChange(index) {
    this.caseActiveGame = index;
    this.changeVideo();
  }

  changeVideo() {
    (this.$refs.HomeTabContent as any).onPersonChanged(
      this.caseActiveList[this.caseActiveGame]
    );
    // this.$refs.HomeTabContent.init({ gameId: this.gameId, clientExtList: this.clientExtList })
  }
  async newList() {
    const dir = this.$i18n.locale == 'zh' ? '' : (this.$i18n.locale + '/')
    let newsListRes: any = await getNewsList(dir);
    let index_recommend: any[] = newsListRes.data.index_recommend;
    let news: dynamicsData[] = newsListRes.data.news;
    this.dynamicsData = [];
    for (let i = 0; i < index_recommend.length; i++) {
      news.map((item) => {
        if (item.id == index_recommend[i]) {
          item.jpgpath =
            "https://official.layabox.com/laya_data/news/" + dir + item.jpgpath;
          this.dynamicsData.push(item);
        }
      });
    }
  }
}
